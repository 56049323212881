import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getArea,
  retornaUrlSiteQuitaboletos,
  retornaUrlSiteQuitapay,
} from "./utils";
import {
  setEhParceiro,
  setLogoCentralAjuda,
  setUrlHomePage,
  setUrlLogo,
  setUrlSite,
} from "./store/reducers/user";
import ThemeCustomization from "./themes";
import ScrollTop from "./components/ScrollTop";
import Routes from "./routes";
import Favicon from "react-favicon";

function App() {
  const dispatch = useDispatch();
  const [faviconUrl, setFaviconUrl] = useState();

  useEffect(() => {
    if (getArea() === "parceiro") {
      dispatch(setEhParceiro(true));
      dispatch(setUrlLogo("/images/logo_cabecalho_parceiro.png"));
      dispatch(setUrlHomePage("/parceiro/central-ajuda"));
      dispatch(setUrlSite(retornaUrlSiteQuitapay()));
      dispatch(setLogoCentralAjuda("/images/central_ajuda_logo_parceiro.png"));
    } else {
      dispatch(setEhParceiro(false));
      dispatch(setUrlLogo("/images/logo_quitaboletos.png"));
      dispatch(setUrlHomePage("/"));
      dispatch(setUrlSite(retornaUrlSiteQuitaboletos()));
      dispatch(setLogoCentralAjuda("/images/central_ajuda_logo.png"));
      setFaviconUrl("/logo_quitaboletos.png");
    }
  }, [dispatch]);

  return (
    <ThemeCustomization>
      {faviconUrl && <Favicon url={faviconUrl} />}

      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  );
}

export default App;
