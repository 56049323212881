import { lazy } from "react";
import Loadable from "../components/Loadable";

const CentralAjudaLayout = Loadable(lazy(() => import("../layout")));
const CentralAjuda = Loadable(lazy(() => import("../pages/admin")));
const Duvidas = Loadable(lazy(() => import("../pages/common/ListagemArtigos")));

const CartaoNaoPassou = Loadable(
  lazy(() => import("../pages/admin/artigos/operacoes/cartao-nao-passou"))
);
const TempoEstorno = Loadable(
  lazy(() => import("../pages/admin/artigos/operacoes/prazo-estorno-aparecer"))
);
const NaoRecebeuContrato = Loadable(
  lazy(() =>
    import("../pages/admin/artigos/operacoes/cliente-nao-recebeu-o-contrato")
  )
);
const BiometriaNaoPassou = Loadable(
  lazy(() => import("../pages/admin/artigos/operacoes/biometria-nao-passou"))
);
const AntiFraudeAutomatico = Loadable(
  lazy(() =>
    import(
      "../pages/admin/artigos/operacoes/quando-operacao-nao-passa-antifraude"
    )
  )
);

const DescontoCupom = Loadable(
  lazy(() =>
    import("../pages/admin/artigos/operacoes/como-desconto-e-aplicado")
  )
);

const DesbloquearAcesso = Loadable(
  lazy(() => import("../pages/admin/artigos/clientes/desbloquear-acesso"))
);

const CentralAjudaRoutes = {
  path: "/",
  element: <CentralAjudaLayout />,
  children: [
    {
      path: "/duvidas/:topico",
      element: <Duvidas />,
    },
    {
      path: "duvidas/cartao-nao-passou",
      element: <CartaoNaoPassou />,
    },
    {
      path: "duvidas/prazo-estorno-aparecer",
      element: <TempoEstorno />,
    },
    {
      path: "duvidas/nao-recebeu-contrato",
      element: <NaoRecebeuContrato />,
    },
    {
      path: "duvidas/biometria-nao-passou",
      element: <BiometriaNaoPassou />,
    },
    {
      path: "duvidas/quando-operacao-nao-passa-antifraude",
      element: <AntiFraudeAutomatico />,
    },
    {
      path: "duvidas/desbloquear-acesso",
      element: <DesbloquearAcesso />,
    },
    {
      path: "duvidas/cupom-de-desconto",
      element: <DescontoCupom />,
    },
    {
      path: "/",
      element: <CentralAjuda />,
    },
  ],
};

export default CentralAjudaRoutes;
