export function normaliza(str) {
  if (!str) {
    return "";
  }

  str = str.trim();
  return str.normalize("NFKD").replace(/[\u0300-\u036f]/g, "");
}

export function getArea() {
  if (window.location.href.indexOf("parceiro") !== -1) return "parceiro";

  return "admin";
}

export function ehProducao() {
  return process.env.REACT_APP_ENVIRONMENT === "PROD";
}
export function ehHomologacao() {
  return process.env.REACT_APP_ENVIRONMENT === "HOMOLOG";
}

export function retornaUrlApp() {
  if (ehProducao() || ehHomologacao()) {
    return "https://app.quitaboletos.com.br";
  }

  return "http://localhost:3001";
}

export function retornaUrlHelp() {
  if (ehProducao() || ehHomologacao()) {
    return "https://ajuda.quitaboletos.com.br";
  }
  return "http://localhost:3000";
}

export function retornaUrlSiteQuitapay() {
  if (ehProducao()) {
    return "https://quitapay.com/";
  }
  if (ehHomologacao()) {
    return "https://app.homologacao.quitapay.com/";
  }

  return "http://localhost:3001/";
}

export function retornaUrlSiteQuitaboletos() {
  if (ehProducao()) {
    return "https://quitaboletos.com.br/";
  }
  if (ehHomologacao()) {
    return "https://site.homologacao.quitaboletos.com/";
  }

  return "http://localhost:3004";
}

export function getLightColorButton(user) {
  if (user.ehParceiro) {
    return "#89c0ff;";
  } else {
    return "#f0fce3";
  }
}
