// material-ui
import { createTheme } from "@mui/material/styles";
import { getArea } from "../utils";

const Palette = (mode) => {
  return createTheme({
    palette: {
      primary: {
        light: getArea() === "parceiro" ? "#0063D5" : "#145401",
        main: getArea() === "parceiro" ? "#0063D5" : "#145401",
        dark: getArea() === "parceiro" ? "#0063D5" : "#145401",
        contrastText: "#ffffff",
      },
      black: {
        light: "#2E302E",
        main: "#2E302E",
        dark: "#2E302E",
        contrastText: "#2E302E",
      },
    },
  });
};

export default Palette;
