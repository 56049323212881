import { lazy } from "react";
import Loadable from "../components/Loadable";

const CentralAjudaLayout = Loadable(lazy(() => import("../layout")));
const CentralAjuda = Loadable(lazy(() => import("../pages/parceiro")));
const Duvidas = Loadable(lazy(() => import("../pages/common/ListagemArtigos")));

const ComissionamentoNasOperacoes = Loadable(
  lazy(() =>
    import("../pages/parceiro/artigos/comissoes/comissionamento-operacoes")
  )
);
const SimulacaoComissao = Loadable(
  lazy(() => import("../pages/parceiro/artigos/comissoes/simulacao-comissao"))
);
const SaqueComissao = Loadable(
  lazy(() => import("../pages/parceiro/artigos/comissoes/saque-comissao"))
);
const ConfigurarComissionamento = Loadable(
  lazy(() =>
    import("../pages/parceiro/artigos/comissoes/configurar-comissionamento")
  )
);
const LinkAutoContratacao = Loadable(
  lazy(() =>
    import("../pages/parceiro/artigos/operacoes/link-auto-contratacao")
  )
);
const Contratar = Loadable(
  lazy(() => import("../pages/parceiro/artigos/operacoes/como-contratar"))
);
const HistoricoOperacoes = Loadable(
  lazy(() => import("../pages/parceiro/artigos/operacoes/historico-operacoes"))
);
const BandeirasAceitas = Loadable(
  lazy(() => import("../pages/parceiro/artigos/operacoes/bandeiras-aceitas"))
);
const CartaoNaoAutorizou = Loadable(
  lazy(() => import("../pages/parceiro/artigos/operacoes/cartao-nao-autorizou"))
);
const StatusOperacoes = Loadable(
  lazy(() => import("../pages/parceiro/artigos/operacoes/status-operacoes"))
);
const CadastrarUsuario = Loadable(
  lazy(() => import("../pages/parceiro/artigos/sistema/cadastrar-usuario"))
);
const IntegrarViaApi = Loadable(
  lazy(() => import("../pages/parceiro/artigos/sistema/integrar-via-api"))
);
const FaixasComissaoParcelamento = Loadable(
  lazy(() =>
    import(
      "../pages/parceiro/artigos/sistema/configuracao-parcelamento-comissao"
    )
  )
);

const CentralAjudaParceirosRoutes = {
  path: "parceiro",
  element: <CentralAjudaLayout />,
  children: [
    {
      path: "central-ajuda",
      element: <CentralAjuda />,
    },
    {
      path: "duvidas/:topico",
      element: <Duvidas />,
    },
    {
      path: "duvidas/comissionamento-nas-operacoes",
      element: <ComissionamentoNasOperacoes />,
    },
    {
      path: "duvidas/simulacao-comissao",
      element: <SimulacaoComissao />,
    },
    {
      path: "duvidas/saque-comissao",
      element: <SaqueComissao />,
    },
    {
      path: "duvidas/configurar-comissionamento",
      element: <ConfigurarComissionamento />,
    },
    {
      path: "duvidas/link-autocontratacao",
      element: <LinkAutoContratacao />,
    },
    {
      path: "duvidas/contratar",
      element: <Contratar />,
    },
    {
      path: "duvidas/cartao-nao-autorizou",
      element: <CartaoNaoAutorizou />,
    },
    {
      path: "duvidas/status-operacoes",
      element: <StatusOperacoes />,
    },
    {
      path: "duvidas/historico-operacoes",
      element: <HistoricoOperacoes />,
    },
    {
      path: "duvidas/bandeiras-aceitas",
      element: <BandeirasAceitas />,
    },
    {
      path: "duvidas/cadastrar-usuario",
      element: <CadastrarUsuario />,
    },
    {
      path: "duvidas/integrar-via-api",
      element: <IntegrarViaApi />,
    },
    {
      path: "duvidas/faixas-comissionamento-parcelamento",
      element: <FaixasComissaoParcelamento />,
    },
  ],
};

export default CentralAjudaParceirosRoutes;
