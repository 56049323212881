import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ehParceiro: false,
  logo: "",
  urlHome: "/",
  urlSite: "",
  logoCentralAjuda: "",
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUrlLogo(state, action) {
      state.logo = action.payload;
    },
    setEhParceiro(state, action) {
      state.ehParceiro = action.payload;
    },
    setUrlHomePage(state, action) {
      state.urlHome = action.payload;
    },
    setUrlSite(state, action) {
      state.urlSite = action.payload;
    },
    setLogoCentralAjuda(state, action) {
      state.logoCentralAjuda = action.payload;
    },
    resetGeral(state) {
      state.logo = initialState.logo;
      state.ehParceiro = initialState.ehParceiro;
      state.urlHome = initialState.urlHome;
      state.urlSite = initialState.urlSite;
      state.logoCentralAjuda = initialState.logoCentralAjuda;
    },
  },
});

export default user.reducer;
export const {
  setUrlLogo,
  setEhParceiro,
  setUrlHomePage,
  setUrlSite,
  setLogoCentralAjuda,
  resetGeral,
} = user.actions;
